import {Dispatch} from 'redux'
import {getHeadlessUrl, NavigationType} from '@wix/wix-to-headless-redirect-client'
import {CommonStoreExtraArgs, GetCommonState} from '../types/state'
import {getLanguage, getLocale, getTimezone, isEditor, isMobile, isPreview, isSite} from '../utils/wix-code-api'

export const SET_BASE_ENVIRONMENT = 'SET_BASE_ENVIRONMENT'
export const SET_FORM_FACTOR = 'SET_FORM_FACTOR'
const HEADLESS_QUERY_PARAM = 'headlessExternalUrls'

export const setBaseEnvironment =
  () =>
  (dispatch: Dispatch, getState: GetCommonState, {wixCodeApi}: CommonStoreExtraArgs) => {
    const state = getState()

    const headless = Boolean(wixCodeApi.location.query?.[HEADLESS_QUERY_PARAM])
    const headlessOrigin = getHeadlessUrl({
      query: wixCodeApi.location.query,
      baseUrl: wixCodeApi.location.baseUrl,
      navParams: {
        logicalName: NavigationType.HOME,
      },
    })
    const homePageUrl = headless ? headlessOrigin : wixCodeApi.location.baseUrl

    dispatch({
      type: SET_BASE_ENVIRONMENT,
      payload: {
        locale: getLocale(wixCodeApi) || state.siteSettings.locale,
        language: getLanguage(wixCodeApi),
        timezone: getTimezone(wixCodeApi),
        mobile: isMobile(wixCodeApi),
        editor: isEditor(wixCodeApi),
        preview: isPreview(wixCodeApi),
        site: isSite(wixCodeApi),
        homePageUrl,
        headless,
      },
    })
  }

export const setFormFactor = (formFactor: string) => ({
  type: SET_FORM_FACTOR,
  payload: formFactor === 'Mobile',
})
